import { gsap } from 'gsap';
import Flickity from 'flickity';

/**
 * Class representing components for managing different UI components.
 */
export class Components {
    
    /**
     * Constructs a Components object.
     */
    constructor() {
        this.menu();
        this.carousel();
        this.accordions();
    }

    /**
     * Accordions.
     */
    accordions() {
        let section = $('article.accordion');
        let heading = $('.heading', section);

        heading.on('click', function(event) {
            event.preventDefault();

            let article = $(this).closest('article');
            article.siblings('article.accordion').removeClass('open');
            article.addClass('open');
        });
    }

    menu() {
        let body = $('body'),
            overlay = $('#overlay');
        
        // Function to toggle menu and body class
        function toggleMenu() {
            $('nav.menu').toggleClass('open');
            body.toggleClass('menu');
            body.toggleClass('fixed');
            body.removeClass('scrolling');
            overlay.toggleClass('open');

            // Update label text
            if ($('nav.menu').hasClass('open')) {
                $('.burger .label-menu').text('Close');
            } else {
                $('.burger .label-menu').text('Menu');
            }
        }
        
        // Click event for burger button in nav.menu
        $('.burger').on('click', function () {
            $(this).toggleClass('open');
            toggleMenu();
        });
    }
    
    /**
     * Initializes carousels using Flickity within sections of the main slider.
     *
     * This function searches for elements with the class `.main .slider` and initializes
     * Flickity carousels within each of them. It sets up the next/previous button controls,
     * updates their states based on the current slide index, and handles dot navigation.
     *
     * @returns {boolean} False if no sections are found; otherwise, void.
     */
    carousel() {
        let sections = gsap.utils.toArray('.slider');

        if (!sections.length) {
            return false;
        }

        sections.forEach(section => {
            let main = $('.main-carousel', section)[0],
                next = $(section).find('.next'),
                prev = $(section).find('.prev'),
                dots = $(section).find('.dots'),
                li = dots.find('li');

            var slider = new Flickity(main, {
                cellAlign: 'left',
                contain: true,
                pageDots: false,
                prevNextButtons: false,
                // adaptiveHeight: true,
            });

            // Event listener for slider select event
            slider.on('select', function() {
                let index = slider.selectedIndex;
                li.removeClass('active');
                li.eq(index).addClass('active');
            });

            // Event listener for dot clicks
            dots.on('click', 'li', function() {
                var index = $(this).index();
                slider.select(index);
            });

            /**
             * Event listener for the next button click.
             * Moves to the next slide.
             */
            next.on('click', function() {
                slider.next();
            });

            /**
             * Event listener for the previous button click.
             * Moves to the previous slide.
             */
            prev.on('click', function() {
                slider.previous();
            });
        });
    }
}
